<template>
  <v-dialog
    v-model="dialog"
    max-width="650"
    scrollable
  >
    <v-card class="text-justify">
      <v-card-title style="word-break: normal">
        Bases y Condiciones legales de Sorteo mes del padre.
        <v-spacer></v-spacer>
        <v-btn
          small
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="scrolltotop">
        <p>
          El presente sorteo es organizado por CeluShop (en adelante el organizador).El sorteo se rige por las bases que se establecen a continuación (en adelante las Bases)          
        </p>
        <p>
          La participación en el sorteo atribuye la condición de participante única y exclusivamente en la medida que se cumplan las condiciones establecidas en las Bases y expresa la aceptación plena e incondicional de las mismas.          
        </p>
        <p>
          Podrán participar del sorteo todos los habitantes de la República Argentina mayores de 18 años que hubieren efectuado una compra mayor a $100.000 en los locales de CeluShop del país. Para acceder al sorteo el interesado deberá ingresar a la página del organizador <a href="https://sorteo.celushop.com.ar" target="_blank">https://sorteo.celushop.com.ar</a> y completar un formulario  con el número de la factura de compra ,fecha de compra, número de Dni y teléfono de contacto, el Dni debe coincidir con el cargado en la factura. En consecuencia, no podrán participar del sorteo aquellos registros incompletos y/o contengan datos falsos e inexactos .Las facturas anuladas por devolución y las compras realizadas por empleados que accedieron a los descuento estipulados por la empresa no participan del sorteo. El plazo para la carga de las mismas será desde el 01/06/2024 al 30/06/2024 inclusive. El sorteo se llevara a cabo el día 03/07/2024 y se publicara el ganador el día 04/07/2024 en la página del organizador y en las redes sociales .El organizador se pondrá en contacto con el ganador obteniendo los datos de la información suministrada por el mismo, el organizador no solicitara al cliente datos personales ni de tarjeta de crédito.          
        </p>
        <p>
          El premio podrá ser retirado por el titular de la factura ganadora presentando el Dni que de fe de su identidad .El premio deberá ser retirado en un local de CeluShop previamente acordado con el organizador. El plazo para la entrega y retiro del premio será desde el 05/07/2024 hasta el 12/07/2024 inclusive.          
        </p>
        <p>
          Al momento de la entrega del premio el organizador no asumirá costos por flete ni traslado del premio          
        </p>
        <p>
          Sin perjuicio de lo estipulado precedentemente en estas bases y condiciones , los concursantes acuerdan que el organizador  no serán responsable por las pérdidas, daños o perjuicios de cualquier tipo que resulten de la aceptación uso o gozo de los premio y/o de la participación en el sorteo.          
        </p>
        <p>
          El ganador del sorteo contara con 10 días corridos  de prueba sobre el premio para cambio inmediato y posterior a esta fecha, la garantía correrá por cuenta del fabricante del producto.          
        </p>
        <p>
          Facebook, Instagram ni cualquier otra red social no patrocina , avala ni administra de modo alguno este sorteo ni está asociado al mismo.
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      val || (document.getElementsByClassName('scrolltotop')[0].scrollTop = 0)
    }
  }
}
</script>

<style>
h3 {
  padding-bottom: 8px;
}
p {
  text-align: justify;
  text-justify: inter-word;
}
</style>