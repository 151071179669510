import Vue from 'vue'
import Vuex from 'vuex'
import config from '../../config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async sendDatos ({}, payload) {
      return await new Promise(async (resolve, reject) => {
        try {
          const res = await fetch(`${config.BASE_URL}/sorteo/celushop`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
          })
    
          const data = await res.json()
    
          resolve(data)
    
        } catch (error) {
          resolve({
            exito: 0,
            message: error
          })
        }
      })
    }
  },
  modules: {
  }
})
