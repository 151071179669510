<template>
  <v-app>
    <v-main :style="imagen" class="pb-0">
      <v-container style="height: 100%;" fluid>
        <router-view/>
      </v-container>
    </v-main>
    <v-footer
      class="d-flex justify-center align-center pa-0 white"
      :height="$vuetify.breakpoint.xs ? 38 : 28"
      dark
      app
    >
      <span
        style="cursor: pointer; font-size: small;"
        class="mx-2 accent--text text-decoration-underline font-weight-medium"
        :class="!$vuetify.breakpoint.xs ? 'body-2' : ''"
        @click="bases = true"
      >
        Bases y condiciones
      </span>
      <v-spacer v-if="!$vuetify.breakpoint.xs"></v-spacer>
      <span
        style="cursor: default; font-size: x-small;"
        class="mx-2 accent--text font-weight-medium"
        :class="!$vuetify.breakpoint.xs ? 'body-2' : ''"
      >
        Copyright &copy;
        <v-hover v-slot="{ hover }">
          <a
            class="accent--text"
            :class="hover ? '' : 'text-decoration-none'"
            href="https://www.borigenbetzel.com.ar"
            target="_blank"
          >
            RFB TECNOLOGÍA SRL.
          </a>
        </v-hover>
        Todos los derechos reservados
      </span>
    </v-footer>
    <BasesYCondiciones
      v-model="bases"
    />
  </v-app>
</template>

<script>
import BasesYCondiciones from './components/BasesYCondiciones.vue'

export default {
  data () {
    return {
      bases: false
    }
  },
  computed: {
    imagen (){
      return [this.$vuetify.breakpoint.xs ? {
        'background-image' : `url(${require('./assets/banner-mobile.webp')})`,
        'background-position' : 'center',
        'background-repeat' : 'no-repeat',
        'background-size' : 'cover',
        'height' : '96vh'
      } : {
        'background-image' : `url(${require('./assets/banner.webp')})`,
        'background-position' : 'center',
        'background-repeat' : 'no-repeat',
        'background-size' : 'cover'
      } ]
    }
  },
  components: {
    BasesYCondiciones
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.swal2-popup {
  font-family: Roboto, sans-serif !important;
  margin-bottom: 28px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>